import { IAction } from 'actions/commonAction';
import { GET_PATHWAY_USER_PROFILE_INFO_ACTION } from 'actions/pathwayBlueprint/getPathwayUserProfileInfo';
import { IPathwayUserProfileInfoDto } from 'api/generated/models';

const initialState: IPathwayUserProfileInfoDto = ({} as unknown) as IPathwayUserProfileInfoDto;

export const pathwayUserProfileInfo = (
    state = initialState,
    action: IAction<IPathwayUserProfileInfoDto>
): IPathwayUserProfileInfoDto => {
    if (action.type === GET_PATHWAY_USER_PROFILE_INFO_ACTION.success) {
        return action.data;
    }
    return state;
};
