export const DOCUMENT_EXTENSIONS = ['.docx'] as const;
export const IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.png'] as const;
export const PRESENTATION_EXTENSIONS = ['.pptx'] as const;
export const SPREADSHEET_EXTENSIONS = ['.csv', '.xlsx'] as const;
export const VIDEO_EXTENSIONS = ['.mov', '.mp4', '.webm', '.ogg'] as const;

export const VALID_UPLOAD_FILE_EXTENSIONS = [
    '.pdf',
    ...DOCUMENT_EXTENSIONS,
    ...IMAGE_EXTENSIONS,
    ...PRESENTATION_EXTENSIONS,
    ...SPREADSHEET_EXTENSIONS,
];
