import { GET_BENEFITS_ACTION } from 'actions/benefits/getBenefits';
import { IMemberBenefitsDto } from 'api/generated/models';

const initialState: IMemberBenefitsDto = ({} as unknown) as IMemberBenefitsDto;

export const memberBenefits = (
    state = initialState,
    action: {
        data?: IMemberBenefitsDto;
        type?: string;
    } = {}
): IMemberBenefitsDto => {
    if (action.type === GET_BENEFITS_ACTION.success) {
        return action.data as IMemberBenefitsDto;
    }
    return state;
};
