import { EnrollmentStatuses, PathwayTypes, UserStatus } from 'api/generated/enums';
import { ViewUserWageIncrease } from 'api/generated/permissions';
import useUserProps from 'hooks/useUserProps';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { getIsPathwayOfType } from 'utilities/pathways';

const enrollmentStatusesToHideActiveContributionsFor = [
    EnrollmentStatuses.ReadyForLaunch,
    EnrollmentStatuses.InvitedToLaunch,
    EnrollmentStatuses.AwaitingTaskList,
    EnrollmentStatuses.BenefitsSelection,
    EnrollmentStatuses.PendingWageUpRequest,
    EnrollmentStatuses.AdvisementNeeded,
    EnrollmentStatuses.AdvisementScheduled,
];

const useContributionProps = () => {
    const { isCurrent, user, yearlyUserInfo } = useUserProps();
    const {
        activeContribution,
        calculatedProjectedReimbursement,
        calculatedProjectedWageUp,
        canViewContribution,
        customProjectedReimbursement,
        customProjectedWageUp,
        isStale,
        pathwayType,
    } = useSelector((state: AppStore) => ({
        activeContribution: isCurrent
            ? state.current?.userProfile?.user?.activeWageUps?.find(
                  (x) => x.year === +state.profileState.selectedYear
              )
            : state.userProfile?.user?.activeWageUps?.find(
                  (x) => x.year === +state.profileState.selectedYear
              ),
        calculatedProjectedReimbursement: state.pathwayUserProfileInfo?.projectedReimbursement,
        calculatedProjectedWageUp: state.pathwayUserProfileInfo?.projectedWageUp,
        canViewContribution: hasSomePermissions(state, ViewUserWageIncrease),
        customProjectedReimbursement: state.pathwayUserProfileInfo?.customProjectedReimbursement,
        customProjectedWageUp: state.pathwayUserProfileInfo?.customProjectedWageUp,
        isStale: state.pathwayUserProfileInfo?.isStale,
        pathwayType: state.pathwayUserProfileInfo?.pathwayType,
    }));
    const userEnrollmentStatusNullOrInForbiddenDisplayList =
        enrollmentStatusesToHideActiveContributionsFor.contains(
            yearlyUserInfo?.enrollmentStatus?.value
        ) || !hasValue(yearlyUserInfo?.enrollmentStatus);
    const canCurrentUserViewContribution =
        isCurrent &&
        !(
            getIsPathwayOfType(pathwayType, PathwayTypes.Spouse, PathwayTypes.Parent) &&
            user?.status === UserStatus.Launching &&
            userEnrollmentStatusNullOrInForbiddenDisplayList
        );
    const shouldDisplayActiveContributionProfileAttribute =
        canViewContribution || canCurrentUserViewContribution;
    const hasActiveContribution = hasValue(activeContribution);
    const hasAnyContributionInfo =
        hasActiveContribution ||
        hasValue(customProjectedWageUp ?? calculatedProjectedWageUp) ||
        hasValue(customProjectedReimbursement ?? calculatedProjectedReimbursement);
    const projectedWageUp = customProjectedWageUp ?? calculatedProjectedWageUp;
    const projectedReimbursement = customProjectedReimbursement ?? calculatedProjectedReimbursement;

    return {
        activeContribution,
        calculatedProjectedReimbursement,
        calculatedProjectedWageUp,
        customProjectedReimbursement,
        customProjectedWageUp,
        hasActiveContribution,
        hasAnyContributionInfo,
        isStale,
        projectedReimbursement,
        projectedWageUp,
        shouldDisplayActiveContributionProfileAttribute,
    };
};

export default useContributionProps;
